import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Container, Row, Col } from 'reactstrap';
import MainContent from '../components/mainContent';
import Layout from '../components/Layout';

const termsPage = ({ data }) => (
  <Layout>
    <div>
      <HelmetDatoCms seo={data.datoCmsTermsAndCondition.seoMetaTags} />
      <Container className="py-4 narrow">
        <Row>
          <Col>
            <h1>{data.datoCmsTermsAndCondition.heading}</h1>
          </Col>
        </Row>
      </Container>

      <MainContent content={data.datoCmsTermsAndCondition.text} />
    </div>
  </Layout>
);

export default termsPage;

export const query = graphql`
  query termsPageQuery {
    datoCmsTermsAndCondition {
      heading
      text
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
